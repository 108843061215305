.half {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.musicContainer {
  // background-color: #000;
  // width: 100%;
  // min-height: 100vh;
  // font-family: Poppins, Arial, sans-serif;
  // display: flex;
  // flex-direction: column;
  // margin: auto;
  width: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  padding: 150px 0;
  background-color: #000;
}
.musics {
  color: #fff;
  width: 90%;
  font-weight: 600;
  border-bottom: 1px solid;
  line-height: 40px;
  text-transform: uppercase;
}
.container {
  // margin: auto;
  // max-width: 2000px;
  // width: 100%;
  // align-items: center;
  // display: flex;
  // flex-direction: column;
  // margin: 150px auto;
  max-width: 2000px;
  margin: auto;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
}
@media screen and (max-width: 975px) {
  .musicContainer {
    padding: 0;
    margin-top: 100px;
  }
}

@media screen and (max-width: 920px) {
  .half {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 10px;
  }
  .musics {
    font-size: 16px;
  }
}
@media screen and (max-width: 785px) {
  .youtubeContainer {
    width: 600px;
    height: 300px;
  }
}
@media screen and (max-width: 680px) {
  .youtubeContainer {
    width: 90%;
    height: 90%;
  }
  .half {
    width: 85%;
  }
}
@media screen and (max-width: 475px) {
  .half {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
