.showsContainer {
  width: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  padding: 150px 0;
  background-color: #000;
  margin: auto;
}
.shows {
  max-width: 2000px;
  margin: auto;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
}
.headerText {
  color: #fff;
  width: 90%;
  font-weight: 600;
  border-bottom: 2px solid #33bbc5;
  line-height: 40px;
  font-size: 24px;
  text-transform: uppercase;
}
.showInfo {
  font-size: 15px;
  line-height: 26px;
  font-weight: 300;
  color: #eee;
  width: 90%;
  letter-spacing: 2px;
}
@media screen and (max-width: 975px) {
  .showsContainer {
    padding: 0;
    margin-top: 100px;
  }
  .headerText {
    font-size: 16px;
    opacity: 0.8p;
  }
  .showInfo {
    font-size: 12px;
    line-height: 20px;
    opacity: 0.6;
  }
}
