.musicVideoContainer {
  width: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  padding: 150px 0;
  background-color: #000;
  margin: auto;
}
.musicVideos {
  max-width: 2000px;
  margin: auto;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
}
.headerText {
  color: #fff;
  width: 90%;
  font-weight: 600;
  border-bottom: 2px solid #33bbc5;
  line-height: 40px;
  font-size: 24px;
  text-transform: uppercase;
}
.showInfo {
  font-size: 15px;
  line-height: 26px;
  font-weight: 300;
  color: #eee;
  width: 90%;
  letter-spacing: 2px;
}
.youtubeContainer {
  height: 365px;
  border: 1px solid #333;
  border-radius: 10px;
  overflow: hidden;
}
.musicRootContainer {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 10px;
}
@media screen and (max-width: 975px) {
  .musicVideoContainer {
    padding: 0;
    margin-top: 100px;
  }
  .headerText {
    font-size: 16px;
    opacity: 0.8p;
  }
}
@media screen and (max-width: 790px) {
  .youtubeContainer {
    height: 400px;
  }
}

@media screen and (max-width: 680px) {
  .musicRootContainer {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
@media screen and (max-width: 500px) {
  .youtubeContainer {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 430px) {
  .half {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .youtubeContainer {
    height: 210px;
  }
}
