.galeri {
  width: 100%;
  display: flex;
  background-color: #000;
  height: 100%;
  flex-direction: column;
}
.galeriBox5 {
  width: 100%;
  display: flex;
  background-image: url("../../assets/osman-tugsal.JPEG");
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.languageSwitcher {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 975px) {
  .galeriBox5 {
    position: absolute;
    top: 0;
  }
}
