.footerContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.5);
}

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
}
.footerText {
  color: #fff;
  width: 90%;
  font-weight: 600;
  border-bottom: 1px solid #fff;
  line-height: 40px;
  text-transform: uppercase;

  margin: 0;
}
.subscribeForm {
  display: flex;
  // margin-top: 20px;
  // width: 50%;
  flex-direction: column;
}

.emailInput {
  padding: 10px;
  border-radius: 4px;
  flex: 1;
  width: 70%;
  height: 20px;
  border: 5px solid #267ea2;
  outline: none;
}

.subscribeButton {
  margin-left: 10px;
  padding: 15px;
  height: 50px;
  background-color: #267ea2;
  color: white;
  border: none;
  border-radius: 8px;

  cursor: pointer;
  font-weight: 800;
  transition: background-color 0.3s ease;

  &:hover {
    color: #fff;
    opacity: 0.9;
  }
}
.inputFieldContainer {
  display: flex;
  align-items: center;
  width: 90%;
}
.subscribeText {
  font-size: 15px;
  line-height: 30px;
  font-style: italic;
  color: #fff;
  font-weight: 500;
  opacity: 0.6;
}
.iconContainer {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.popupHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media screen and (max-width: 1048px) {
  .inputFieldContainer {
    width: 100%;
  }
  .footerText {
    font-size: 20px;
  }
  .subscribeText {
    line-height: 20px;
  }
  .footerContainer {
    width: 60%;
  }
}
@media screen and (max-width: 900px) {
  .emailInput {
    padding: 5px;
    border-radius: 0;
    border: 1px;
  }
  .subscribeButton {
    height: 10px;
    align-items: center;
    display: flex;
    font-size: 10px;
    border-radius: 0;
  }
  .subscribeText {
    font-size: 10px;
  }
}
@media screen and (max-width: 524px) {
  .icon {
    width: 15px;
  }
  .iconContainer {
    gap: 10px;
  }
  .footerText {
    font-size: 16px;
  }
}
