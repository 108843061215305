.languageSwitcherSection {
  .languageSwitcherButton {
    width: 50%;
    border: none;
    backdrop-filter: blur(40px);
    color: #777;
    font-size: 12px;
    font-weight: 300;
    padding: 10px 18px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #333;
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .languageSwitcherButton.active {
    background-color: #194860;
    backdrop-filter: blur(40px);
    color: white;
  }
}
