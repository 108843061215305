.galleryContainer {
  width: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  padding: 150px 0;
  background-color: #000;
  margin: auto;
}

.gallery {
  max-width: 2000px;
  margin: auto;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
}

.imageContainer {
  margin: 10px;
  border: 1px solid white;
  box-sizing: border-box;
  width: 90%;
  height: 90%;
}
.galleryText {
  color: #fff;
  width: 90%;
  font-weight: 600;
  border-bottom: 2px solid #33bbc5;
  line-height: 40px;
  font-size: 24px;
  text-transform: uppercase;
}
.galleryBox {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;
}
@media screen and (max-width: 1122px) {
  .galleryBox {
    justify-items: center;
  }
}
@media screen and (max-width: 975px) {
  .galleryContainer {
    padding: 0;
    margin-top: 100px;
  }
}
@media screen and (max-width: 920px) {
  .galleryText {
    font-size: 16px;
  }
  .galleryBox {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
